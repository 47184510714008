<script>
export default {
  name: 'index'
}
</script>

<template>
  <div class="index-policy">
    <div class="begin">
      <div class="content p-4 lg:p-8">
        <div class="header">
          <div class="flex items-center justify-center">
            <svg-icon
              name="ic_logo"
              original
              class="w-1/3 md:w-1/4 xl:w-1/12"
            />
          </div>
        </div>
        <br />

        <div class="body">
          <h1>Politique de confidentialité des données de la plateforme</h1>

          <h2>Table des matières</h2>
          <ol class="list-decimal list-inside">
            <li><a href="#engagement">Engagement</a></li>
            <li><a href="#objet-presente-politique">Objet de la présente politique</a></li>
            <li><a href="#origine-donnees-caractere-personnel">Origine des données à caractère personnel</a></li>
            <li><a href="#identite-responsable-traitement">Identité du responsable de traitement</a></li>
            <li>
              <a href="#finalites-traitements-donnees-caractere-personnel">Finalités des traitements des données à caractère personnel</a>
              <ol>
                <li><a href="#necessite-collecte">Nécessité de la collecte</a></li>
                <li><a href="#durees-conservation">Durées de conservation</a></li>
                <li><a href="#description-traitements">Description des Traitements</a></li>
              </ol>
            </li>
            <li><a href="#sous-traitants-destinataires-donnees">Sous-traitants et destinataires des données</a></li>
            <li><a href="#violation-donnees-caractere-personnel">Violation de données caractère personnel (Article 427 du CN)</a></li>
            <li>
              <a href="#droits-personnes-utilisateurs">Les droits des personnes Utilisateurs</a>
              <ol>
                <li><a href="#droits-utilisateurs-donnees-caractere-personnel">Droits des Utilisateurs sur leurs Données à caractère personnel</a></li>
                <li><a href="#modalites-exercice-droits-personnes-concernees">Modalités d’exercice des droits des personnes concernées</a></li>
              </ol>
            </li>
            <li><a href="#securite">Sécurité</a></li>
            <li><a href="#condition-application-politique">Conditions d’application de la politique</a></li>
          </ol>

          <h2 id="engagement">1. Engagement</h2>
          <p>
            Le respect de votre vie privée est un droit fondamental et une préoccupation majeure de cette plateforme.
            Nous nous sommes engagés à respecter la réglementation Béninoise sur la protection des données personnelles Conformément à la loi 2017-20 portant code du numérique en République du Bénin (Livre 5ième relatif à la protection des données personnelles et de la vie privée) en République du Bénin.
            Par ailleurs, nous avons accompli auprès de l’Autorité en charge au Bénin de la protection des données à caractère personnel, les formalités nécessaires aux traitements des données personnelles collectées par cette plateforme.
            Dans la suite de ce document, se trouve tous les détails sur les données à caractère personnel que nous collectons auprès des Utilisateurs.
          </p>

          <h2 id="objet-presente-politique">2. Objet de la présente politique</h2>
          <p>
            Informer les Utilisateurs des finalités du traitement des données à caractère personnel qui sont collectées, des catégories des données collectées, de la durée de conservation desdites données, de la base légale du traitement des données et des mesures prises pour garantir la sécurité et la confidentialité de données personnelles collectées.
            Cette politique a été élaborée, le 21 Août 2023 et reste en vigueur jusqu’à la prochaine mise-à-jour.
          </p>

          <h2 id="origine-donnees-caractere-personnel">3. Origine des données à caractère personnel</h2>
          <p>
            Toutes les Données à caractère personnel sont collectées directement auprès des Utilisateurs lors de leur inscription sur la plateforme.
          </p>

          <h2 id="identite-responsable-traitement">4. Identité du responsable de traitement</h2>
          <p>
            « Le responsable du traitement est, au sens du Code du Numérique (CN), la personne qui détermine les moyens et les finalités du traitement. Le sous-traitant est une personne traitant des données à caractère personnel pour le compte du responsable du traitement. Il agit sous l’autorité du responsable du traitement et sur instruction de celui-ci ».
            Mme Judith MAFFON GBEHINTO, représentant légal de l’Association Béninoise pour la Promotion de la Famille (ABPF) est le Responsable de traitement des données à caractère personnel recueillies auprès des Utilisateurs.
          </p>

          <h2 id="finalites-traitements-donnees-caractere-personnel">5. Finalités des traitements des données à caractère personnel</h2>

          <h3 id="necessite-collecte">5.1. Nécessité de la collecte</h3>
          <p>
            Lors de son inscription, les Utilisateurs communiquent sur la plateforme, certaines Données à caractère personnel nécessaires à la fourniture de services. Si les Utilisateurs ne souhaitent pas communiquer les informations qui leurs sont demandées, ou s’opposent à fournir leurs données personnelles, il se peut que l’Utilisateurs ne puisse pas accéder aux Services proposés sur la plateforme.
          </p>

          <h3 id="durees-conservation">5.2. Durées de conservation</h3>
          <p>
            Toutes les Données à caractère personnel collectées sont traitées et conservées pour une durée limitée en fonction de la finalité du traitement et de la législation applicable aux Services. A l’expiration des durées de conservation, les Données à caractère personnel sont supprimées de façon définitive ou archivées.
          </p>

          <h3 id="description-traitements">5.3. Description des Traitements</h3>
          <p>Ci-après la liste des Traitements pour lesquels l’ABPF agit en qualité de Responsable de Traitement :</p>

          <p class="underline font-semibold">Transfert des données</p>
          <table class="border border-collapse">
            <tr class="border">
              <td class="border"><strong>Raison Sociale</strong></td>
              <td class="border"><strong>Base légale</strong></td>
              <td class="border"><strong>Rôle</strong></td>
              <td class="border"><strong>Pays</strong></td>
            </tr>
            <tr class="border">
              <td class="border"><a href="https://www.41devs.com" target="_blank">Cabinet 41 Devs</a></td>
              <td class="border">Relation contractuelle</td>
              <td class="border">Conception et maintenance de la plateforme</td>
              <td class="border">Bénin</td>
            </tr>
            <tr class="border">
              <td class="border"><a href="https://www.linode.com" target="_blank">Linode</a></td>
              <td class="border">Relation contractuelle</td>
              <td class="border">Hébergeur de données</td>
              <td class="border">États-Unis</td>
            </tr>
            <tr class="border">
              <td class="border"><a href="https://www.linode.com" target="_blank">Linode</a></td>
              <td class="border">Relation contractuelle</td>
              <td class="border">Hébergeur de données</td>
              <td class="border">États-Unis</td>
            </tr>
          </table>

          <p class="underline font-semibold">Données Collectées</p>
          <table class="border border-collapse mb-4">
            <thead>
             <tr class="border">
               <th class="border">Données</th>
               <th class="border">Finalités</th>
               <th class="border">Bases Légales</th>
               <th class="border">Durée de Conservation</th>
               <th class="border">Mesures de sécurité</th>
            </tr>
            </thead>
            <tbody>
             <tr class="border">
               <td class="border">
                Traitement : Création de compte utilisateur pour les particuliers<br>
                Données de connexion : Identifiant, mot de passe.<br>
                Données d'identification : Nom, Prénom, Sexe, Date de Naissance, Profession, Numéro de téléphone.<br>
                Données géographiques : Commune.
              </td>
               <td class="border">
                Création de compte et accès aux services de la plateforme. Les données géographiques permettent d'indiquer à l'utilisateur les Points SSR partenaires à proximité.
              </td>
               <td class="border">
                Acceptation de la politique de confidentialité des données disponible sur la plateforme.
              </td>
               <td class="border">
                Les données sont conservées durant la période d'abonnement de l'utilisateur à la plateforme. En cas de désabonnement ou de retrait de la plateforme, les données sont anonymisées. Par ailleurs, en cas d'inactivité au bout d'une période de 5 ans, le compte de l'utilisateur est désactivé et ses données sont anonymisées.
              </td>
               <td class="border">
                Compte protéger par un mot de passe et chiffré par le Protocol SSL, la disponibilité des données est assurée par l’hébergement de la base de données et de la sauvegarde des données auprès d'un Hébergeur de données, les personnes impliquées dans le traitement des données ont signé un engagement de confidentialité.
              </td>
            </tr>
             <tr class="border">
               <td class="border">
                Traitement : Création compte utilisateurs pour les professionnels de santé<br>
                Données de connexion : Identifiant, mot de passe.<br>
                Autres Données : Nom, Prénom, Sexe, Date Naissance, Profession, Numéro de téléphone, Biographie, Centre SSR, Spécialité, Copie carte d'identité, Justificatif de profession.<br>
                Données géographiques : Commune
              </td>
               <td class="border">
                Création de compte et examen du dossier de candidature des professionnels SSR.
              </td>
               <td class="border">
                Accord de partenariat et acceptation de la politique de confidentialité des données
              </td>
               <td class="border">
                Les données sont conservées durant la période d'abonnement de l'utilisateur à la plateforme. En cas de désabonnement ou de retrait de la plateforme, les données sont anonymisées. Par ailleurs, en cas d'inactivité au bout d'une période de 5 ans, le compte de l'utilisateur est désactivé et ses données sont anonymisées.
              </td>
               <td class="border">
                Compte protéger par un mot de passe et chiffré par le Protocol SSL, la disponibilité des données est assurée par la sauvegarde des données auprès d'un Hébergeur de données, les personnes impliquées dans le traitement des données ont signé un engagement de confidentialité
              </td>
            </tr>
             <tr class="border">
               <td class="border">
                Traitement : Discuter avec un agent de santé<br>
                Données : Identifiant de l'utilisateur, Numéro de téléphone
              </td>
               <td class="border">
                Faciliter aux utilisateurs d'entrer en contact avec les professionnels SSR pour une prise en charge (Consultation, Conseils, Orientation, PEC)
              </td>
               <td class="border">
                Acceptation de la politique de confidentialité des données disponible sur la plateforme.
              </td>
               <td class="border">
                Les données sont conservées durant la période d'abonnement de l'utilisateur à la plateforme. En cas de désabonnement ou de retrait de la plateforme, les données sont anonymisées. Par ailleurs, en cas d'inactivité au bout d'une période de 5 ans, le compte de l'utilisateur est désactivé et ses données sont anonymisées.
              </td>
               <td class="border">
                Compte protéger par un mot de passe et chiffré par le Protocol SSL, la disponibilité des données est assurée par la sauvegarde des données auprès d'un Hébergeur de données, les professionnels SSR sont soumis au secret médical.
              </td>
            </tr>
             <tr class="border">
               <td class="border">
                Traitement : Suivre son cycle menstruel<br>
                Données : Durée des règles, date des dernières règles, nature du cycle
              </td>
               <td class="border">
                Faciliter aux utilisateurs le suivi de son cycle menstruel
              </td>
               <td class="border">
                Acceptation de la politique de confidentialité des données disponible sur la plateforme.
              </td>
               <td class="border">
                Les données sont conservées durant la période d'abonnement de l'utilisateur à la plateforme. En cas de désabonnement ou de retrait de la plateforme, les données sont anonymisées. Par ailleurs, en cas d'inactivité au bout d'une période de 5 ans, le compte de l'utilisateur est désactivé et ses données sont anonymisées.
              </td>
               <td class="border">
                Compte protéger par un mot de passe et chiffré par le Protocol SSL, la disponibilité des données est assurée par la sauvegarde des données auprès d'un Hébergeur de données, les personnes impliquées dans le traitement des données ont signé un engagement de confidentialité
              </td>
            </tr>
             <tr class="border">
               <td class="border">
                Traitement : Garder sa session ouverte (Usage de cookies)<br>
                Données de connexion : Identifiant, mot de passe.
              </td>
               <td class="border">
                Faciliter l'accès rapide à la plateforme lors d'une reconnexion.
              </td>
               <td class="border">
                Les utilisateurs consentent à garder ouverte ou non leur session au moment où ils quittent la page ou ils se déconnectent.
              </td>
               <td class="border">
                Les données de connexion sont conservées pendant (01) un mois. Passé ce délai, l'utilisateur est invité à saisir à nouveau ses identifiants de connexion.
              </td>
               <td class="border">
                Compte protéger par un mot de passe et chiffré par le Protocol SSL, la disponibilité des données est assurée par la sauvegarde des données auprès d'un Hébergeur de données, les personnes impliquées dans le traitement des données ont signé un engagement de confidentialité
              </td>
            </tr>
             <tr class="border">
               <td class="border">
                Traitement : Découvrir les centres SSR<br>
                Données géographiques : Commune.
              </td>
               <td class="border">
                Rechercher les Centres SSR partenaires à proximité de la situation géographique de l'utilisateur
              </td>
               <td class="border">
                Acceptation de la politique de confidentialité des données disponible sur la plateforme.
              </td>
               <td class="border">
                Les données sont conservées durant la période d'abonnement de l'utilisateur à la plateforme. En cas de désabonnement ou de retrait de la plateforme, les données sont anonymisées. Par ailleurs, en cas d'inactivité au bout d'une période de 5 ans, le compte de l'utilisateur est désactivé et ses données sont anonymisées.
              </td>
               <td class="border">
                Compte protéger par un mot de passe et chiffré par le Protocol SSL, la disponibilité des données est assurée par la sauvegarde des données auprès d'un Hébergeur de données, les personnes impliquées dans le traitement des données ont signé un engagement de confidentialité.
              </td>
            </tr>
             <tr class="border">
               <td class="border">
                Traitement : Tester et analyser ses connaissances<br>
                Néant
              </td>
               <td class="border">
                Néant
              </td>
               <td class="border">
                Néant
              </td>
               <td class="border">
                Néant
              </td>
               <td class="border">
                Néant
              </td>
            </tr>
            </tbody>
          </table>

          <h2 id="sous-traitants-destinataires-donnees">6. Sous-traitants et destinataires des données</h2>
          <p>Usage interne : Les Données à caractère personnel des Utilisateurs peuvent être traitées par les professionnels de Santé Sexuelle et Reproductive (SSR) inscrits sur la plateforme ou les sous-traitants dans le strict respect des règles de protection des données personnelles.</p>
          <p>Le Cabinet 41 Devs est intervenu comme un sous-traitant pour la conception de la plateforme. Il assure également la maintenance et la mise-à-jour de la plateforme. Ce cabinet est situé au Bénin.</p>
          <p>Par ailleurs, la société Linode LLC est également un sous-traitant qui assure l’hébergement des données renseignées sur la plateforme. Ce dernier a son siège à Philadelphie et les données sont stockées aux États-Unis.</p>
          <p>Transfert de données personnelles : Étant donné que l’hébergeur des données est situé dans un État tiers à la CEDEAO, il y a lieu de considérer qu’il y a transfert des données des Utilisateurs de la plateforme vers les États-Unis.</p>

          <h2 id="violation-donnees-caractere-personnel">7. Violation de données à caractère personnel (Article 427 du CN)</h2>
          <ol>
            <li>Si l’ABPF a connaissance d’un incident affectant les Données à caractère personnel (accès non autorisé, perte, divulgation ou altération de données), elle le notifie aux Utilisateurs concernés dans les meilleurs délais.</li>
            <li>La notification décrit la nature de l’incident, les conséquences probables de l’incident, les mesures prises ou proposées par l’ABPF en réponse à l’incident et précise qui est leur interlocuteur auprès de l’Association.</li>
            <li>Cette notification de la violation des données personnelles est portée également à la connaissance de l’APDP.</li>
          </ol>

          <h2 id="droits-personnes-utilisateurs">8. Les droits des personnes Utilisateurs</h2>

          <h3 id="droits-utilisateurs-donnees-caractere-personnel">8.1. Droits des Utilisateurs sur leurs Données à caractère personnel</h3>
          <p>Conformément à la réglementation en vigueur en République du Bénin, les Utilisateurs disposent des droits suivants :</p>
          <ul>
            <li>Droit d'accès (article 437 du CN), de rectification et de suppression (article 441 du CN), de mise à jour, de complétude des Données à caractère personnel : Les utilisateurs ont le droit d’accéder aux Données à caractère personnel renseignées sur la plateforme et d’en demander la rectification ou la mise à jour.</li>
            <li>Droit de retirer à tout moment un consentement (article 390 alinéa 3 du CN) si ce traitement est fondé sur le consentement.</li>
            <li>Droit d’opposition au traitement des Données à caractère personnel (article 440 du CN) : Les Utilisateurs peuvent à tout moment s’opposer au traitement de leurs Données à caractère personnel en se désinscrivant de la plateforme.</li>
            <li>Droit à un recours : Les utilisateurs ont le droit de saisir l’Autorité de Protection des Données Personnelles (APDP) de toute plainte ou réclamation en cas de violation de leur vie privée ou en cas d’obstacles à l’exercice de leurs droits à l’adresse : <a href="mailto:contact@apdp.bj" target="_blank">contact@apdp.bj</a>.</li>
          </ul>

          <h3 id="modalites-exercice-droits-personnes-concernees">8.2. Modalités d’exercice des droits des personnes concernées</h3>
          <p>Si les Utilisateurs souhaitent savoir comment leurs données sont traitées, ou exercer leurs droits, ils peuvent contacter l’ABPF à l’adresse suivante : abpf@abpf.org.</p>
          <p>Dans ce cas, les Utilisateurs doivent indiquer les Données à caractère personnel qu’ils souhaiteraient que l’ABPF corrige, mette à jour ou supprime, en s’identifiant de manière précise avec une copie d’une pièce d’identité (carte d’identité ou passeport) ou tout autre élément permettant de justifier de son identité.</p>

          <h2 id="securite">9. Sécurité des données</h2>
          <p>La plateforme met en œuvre toutes les mesures techniques afin d’assurer la sécurité des traitements et la confidentialité des Données à caractère personnel recueillies à travers l’usage d’un mot de passe et le cryptage de ce dernier par le Protocol SSL.</p>
          <p>La confidentialité des échanges sur la plateforme est assurée par le chiffrement des bases de données et la disponibilité des données est garantie par la sauvegarde des données chez l’hébergeur Linode LLC.</p>
          <p>Par ailleurs, le concepteur a intégré à la plateforme, dès la conception et par défaut, toutes les précautions utiles, au regard de la nature des données à caractère personnel et des risques présentés par le traitement, les mesures de sécurité appropriées afin de garantir la sécurité des données à caractère personnel et, notamment, d’empêcher qu’elles soient déformées, endommagées, ou que des tiers non autorisés y aient accès.</p>
          <p>Aussi, voudrions-nous rappeler que les professionnels SSR sont soumis au secret médical et les personnes impliquées dans le traitement des données personnelles sont formés sur la réglementation en vigueur en matière de protection des données personnelles notamment sur le Livre 5 du Code du Numérique (CN). En cas de sous-traitance, un contrat de confidentialité est proposé à ce dernier.</p>

          <h2 id="condition-application-politique">10. Conditions d’application de la politique</h2>
          <p>La présente politique est susceptible d’être modifiée, complétée ou mise à jour afin de prendre en compte toute évolution légale, réglementaire, jurisprudentielle et/ou technique.</p>
          <p>En cas de modification significative de la présente Politique (relatives aux finalités de traitement, aux Données à caractère personnel collectées, à l’exercice des droits, au transfert des Données à caractère personnel), l’ABPF s’engage à en informer les Utilisateurs par tout moyen dans un délai minimum de trente (30) jours avant leur date de prise d’effet.</p>
          <p>En cas de désaccord des Utilisateurs avec les termes de la nouvelle Politique, ces derniers pourraient se passer des services de la plateforme.</p>
          <p>Passé ce délai, tout accès et utilisation des Services de la plateforme sera soumis à la nouvelle Politique.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/style/sass/variables";
h1, h2 {
  text-align: center;
  color: $pass_sidebar_background;
}
h1 {
  font-size: 2.5em;
  font-weight: bold;
}
h2 {
  font-size: 1.8em;
  font-weight: bold;
}
h3 {
  font-size: 1.2em;
  font-weight: bold
}
p {
  margin: 10px 0;
  line-height: 1.6;
}
ul, ol {
  margin: 10px 0 10px 20px;
}
li {
  margin: 5px 0;
}
a {
  color: #1e90ff;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.content {
  max-width: 800px;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
</style>
